<script setup lang="ts">
import PrimeInputNumber from 'primevue/inputnumber';
defineProps<{
  error?: string | undefined;
}>();

const value = defineModel('modelValue', {
  type: Number,
  default: 1,
});
</script>

<template>
  <label
    class="block flex-1 text-left text-sm font-medium leading-3 text-gray-iron-700"
    :class="$attrs.class"
  >
    <label class="mb-1.5 block">
      <slot></slot>
    </label>

    <PrimeInputNumber v-model="value" v-bind="$attrs">
      <template #incrementbuttonicon>
        <span class="pi pi-plus" />
      </template>
      <template #decrementbuttonicon>
        <span class="pi pi-minus" />
      </template>
    </PrimeInputNumber>

    <small class="mt-1 block text-left text-xs text-red-600">
      {{ error }}
    </small>
  </label>
</template>
